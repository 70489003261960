import React, { useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DataHubImage,
  FileWithSize,
  ImageUploadError,
  ImageUploadErrorEnum,
  ProductPublishMode,
} from "../../types";
import DummyLink from "../DummyLink";
import {
  FormSectionSubHeader,
  FormSubDescription,
  FormSubSection,
  FormSubSectionContent,
} from "../FormSection";
import ImageGuidelinesModal from "../ImageGuidelinesModal";
import ImageModal from "../ImageModal";
import ProductThumbnails from "./ProductThumbnails";
import ImageDrop from "./ImageDrop";
import ImageAccordion from "./ImageAccordion";

const MIN_PRODUCT_IMAGE_WIDTH = 720;
const MIN_PRODUCT_IMAGE_HEIGHT = 720;
const MAX_IMAGE_COPYRIGHT_LENGTH = 100;
const MAX_IMAGE_ALT_TEXT_LENGTH = 250;

type ImageSectionProps = {
  publishMode: ProductPublishMode;
  isProductGroup?: boolean;
};

const ImageSection = ({ publishMode, isProductGroup }: ImageSectionProps) => {
  const { t } = useTranslation();
  const { watch, getValues, setError, clearErrors } = useFormContext();
  const originalImages = watch("originalImages");

  const [viewImage, setViewImage] = useState<DataHubImage | null>();
  const [uploadError, setUploadError] = useState<ImageUploadError[]>([]);
  const [showImageGuidelines, setShowImageGuidelines] = useState(false);

  const imageValidator = useCallback(() => {
    const images = getValues()["images"] as DataHubImage[];
    clearErrors(["altText", "imageCopyright"]);
    if (images?.length) {
      images.forEach((image, index) => {
        if (image.altText && image.altText.length > MAX_IMAGE_ALT_TEXT_LENGTH) {
          setError(`altText[${index}]`, {
            type: "maxLength",
            message: t("validationErrors.textTooLong", { max: MAX_IMAGE_ALT_TEXT_LENGTH }),
          });
        }
        if (publishMode !== "draft" && (!image.copyright || image.copyright === "")) {
          setError(`imageCopyright[${index}]`, {
            type: "required",
            message: t("validationErrors.required"),
          });
        } else if (image.copyright && image.copyright.length > MAX_IMAGE_COPYRIGHT_LENGTH) {
          setError(`imageCopyright[${index}]`, {
            type: "maxLength",
            message: t("validationErrors.textTooLong", { max: MAX_IMAGE_COPYRIGHT_LENGTH }),
          });
        }
      });
    }

    if (publishMode === "draft") {
      return true;
    }

    return !images || images.length === 0 ? "imageUpload.mustHaveImages" : true;
  }, [publishMode, getValues, clearErrors, setError, t]);

  const fileValidator = useCallback((file: FileWithSize) => {
    if (file.height < MIN_PRODUCT_IMAGE_HEIGHT || file.width < MIN_PRODUCT_IMAGE_WIDTH) {
      return {
        type: ImageUploadErrorEnum.TooSmallDimensions,
        variables: {
          name: file.file.name,
          height: String(file.height),
          width: String(file.width),
        },
      };
    }
  }, []);

  return (
    <FormSubSection>
      <FormSubDescription>
        <FormSectionSubHeader>{t("productInfo.productImages")}</FormSectionSubHeader>
        {isProductGroup ? (
          <p>{t("productInfo.editImagesDescription")}</p>
        ) : (
          <>
            <p>{t("productInfo.imagesDescription")}</p>
            <DummyLink onClick={() => setShowImageGuidelines(true)}>
              {t("productInfo.imagesSeeExamplesLink")}
            </DummyLink>
          </>
        )}
        {showImageGuidelines && (
          <ImageGuidelinesModal onHide={() => setShowImageGuidelines(false)} />
        )}
      </FormSubDescription>
      <FormSubSectionContent>
        <Form.Group controlId="images">
          {!isProductGroup && <Form.Label>{t("productInfo.addImages")} *</Form.Label>}
          <Controller
            name="images"
            defaultValue={[]}
            rules={{
              validate: imageValidator,
            }}
            render={({ onChange, value, ref }) => {
              const images = value as DataHubImage[];
              return (
                <>
                  {!isProductGroup && (
                    <ImageDrop
                      images={images}
                      onChange={onChange}
                      setUploadError={setUploadError}
                      uploadError={uploadError}
                      fileValidator={fileValidator}
                      imageRef={ref}
                    />
                  )}
                  <ProductThumbnails
                    isProductGroup={isProductGroup}
                    images={images}
                    originalImages={originalImages}
                    onChange={onChange}
                    onImageClick={(img) => {
                      setViewImage(img);
                    }}
                  />
                  {isProductGroup && (
                    <ImageAccordion
                      images={images}
                      onChange={onChange}
                      setUploadError={setUploadError}
                      uploadError={uploadError}
                      fileValidator={fileValidator}
                    />
                  )}
                </>
              );
            }}
          />

          <div className="mt-3">
            {viewImage && (
              <ImageModal
                images={watch("images")}
                viewImage={viewImage}
                onHide={() => {
                  setViewImage(null);
                }}
              />
            )}
          </div>
        </Form.Group>
      </FormSubSectionContent>
    </FormSubSection>
  );
};

export default ImageSection;
