import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { DataHubImage, ImageOrientation } from "../../types";
import { arrayMove } from "../../utils/functions";
import { AltTextGuidelineModal } from "../altTextGuidelineModal";
import PlainDropdown from "../Dropdown";
import DummyLink from "../DummyLink";
import Icon from "../Icon";
import ProductThumbnail from "../ProductThumbnail";
import RadioButton from "../RadioButton";

type ChangeHandler = (images: DataHubImage[]) => void;

type ProductThumbnailsProps = {
  images: DataHubImage[];
  originalImages: DataHubImage[];
  onChange: ChangeHandler;
  onImageClick: (image: DataHubImage) => void;
  isProductGroup?: boolean;
};

const ImageDivider = styled.hr`
  width: 100%;
  border-top: 0.5px solid var(--color-gray-400);
  margin-top: 0;
  margin-bottom: 2rem;
`;

const OrderDropdownContainer = styled.div`
  margin-bottom: 9px;
`;

const changeInImages = (
  imagePropName: "copyright" | "altText",
  images: DataHubImage[],
  index: number,
  value: string
) => {
  return images.map((img, i) => {
    if (i === index) {
      return {
        ...img,
        [imagePropName]: value,
      };
    }
    return img;
  });
};

const ProductThumbnails = ({
  images,
  originalImages,
  onChange,
  onImageClick,
  isProductGroup,
}: ProductThumbnailsProps) => {
  const { errors, trigger } = useFormContext();
  const { t } = useTranslation();

  const [altModal, setAltModal] = useState(false);

  const shouldTriggerValidation = errors?.imageCopyright?.length;

  const orderOptions = images.map((_, index) => {
    return { text: `${index + 1}.`, value: index.toString() };
  });

  const coverPhotoLabel = <span>{t("productInfo.coverPhoto")}</span>;
  const chooseCoverPhotoLabel = (
    <span className="color-gray-600">{t("productInfo.chooseCoverPhoto")}</span>
  );

  return (
    <div className={isProductGroup ? "" : "mt-5"}>
      {images.map((image, index) => (
        <div key={index} className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <RadioButton
              id={`coverPhoto-${image.filename}`}
              name="coverPhoto"
              value={`${index}`}
              label={image.coverPhoto ? coverPhotoLabel : chooseCoverPhotoLabel}
              checked={image.coverPhoto}
              onChange={(event) => {
                const coverIndex = parseInt(event.target.value);
                const newArray = images.map((img, imgIndex) => {
                  return { ...img, coverPhoto: coverIndex === imgIndex };
                });
                onChange(newArray);
              }}
            />
            <OrderDropdownContainer>
              <PlainDropdown
                toggleBorder
                items={orderOptions}
                selected={orderOptions[index]}
                onChange={(selectedItem) => {
                  const newIndex = parseInt(selectedItem.value);
                  if (newIndex !== index) {
                    onChange(arrayMove(images, index, newIndex));
                  }
                }}
              />
            </OrderDropdownContainer>
          </div>
          <ProductThumbnail
            image={image}
            onDelete={(deleted) => {
              const remainingImages = images.filter((img) => img.filename !== deleted.filename);
              const coverImage =
                remainingImages.find((img) => img.coverPhoto) ?? remainingImages[0];
              onChange(
                remainingImages.map((img) => {
                  return { ...img, coverPhoto: img === coverImage };
                })
              );
            }}
            onClick={onImageClick}
            inputControl={
              <>
                <Form.Group controlId={`images${index}.copyright`}>
                  <Form.Label>{t("productInfo.copyrightLabel")}*</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors?.imageCopyright?.[index]}
                    value={images[index].copyright || ""}
                    onChange={(e) => {
                      onChange(changeInImages("copyright", images, index, e.target.value));
                      shouldTriggerValidation && trigger("images");
                    }}
                  />
                  {errors?.imageCopyright?.[index] && (
                    <p className="text-danger">{errors.imageCopyright[index].message}</p>
                  )}
                </Form.Group>
                <Form.Group controlId={`images${index}.altText`}>
                  <Form.Label>{t("productInfo.altTextLabel")}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors?.altText?.[index]}
                    value={images[index].altText || ""}
                    onChange={(e) =>
                      onChange(changeInImages("altText", images, index, e.target.value))
                    }
                  />
                  {errors?.altText?.[index] && (
                    <p className="text-danger">{errors.altText[index].message}</p>
                  )}
                </Form.Group>
              </>
            }
          />
          <div className="row mb-3">
            <div className="col-5 col-md-3 pr-0 color-gray-600 text-small">
              {(image.originalWidth ?? false) && (
                <>
                  {t("productInfo.uploadedImageDimensions", {
                    width: image.originalWidth,
                    height: image.originalHeight,
                    orientation:
                      image.orientation === ImageOrientation.Landscape
                        ? t("productInfo.orientationLandscape")
                        : t("productInfo.orientationPortrait"),
                  })}
                </>
              )}
            </div>
            {index === 0 && (
              <>
                <div className="col ml-0 ml-md-2 color-gray-600 text-small">
                  <div className="d-flex">
                    <Icon name="questionCircle" size="medium" color="primary-dark" />
                    <DummyLink
                      className="ml-2 color-primary-dark"
                      onClick={() => setAltModal(!altModal)}
                    >
                      {t("altTextGuideline.openInstructions")}
                    </DummyLink>
                  </div>
                </div>
                {altModal && <AltTextGuidelineModal onHide={() => setAltModal(false)} />}
              </>
            )}
          </div>
          {index + 1 !== images.length && <ImageDivider />}
        </div>
      ))}

      {isProductGroup && (
        <>
          <ImageDivider />
          <h5 className="mt-4">{t("productInfo.originalImageTitle")}</h5>
          {originalImages?.map((image, index) => (
            <div key={index} className="d-flex flex-column">
              <ProductThumbnail
                image={image}
                onDelete={(deleted) => {
                  const remainingImages = originalImages.filter(
                    (img) => img.filename !== deleted.filename
                  );
                  const coverImage =
                    remainingImages.find((img) => img.coverPhoto) ?? remainingImages[0];
                  onChange(
                    remainingImages.map((img) => {
                      return { ...img, coverPhoto: img === coverImage };
                    })
                  );
                }}
                showDelete={false}
                onClick={onImageClick}
                inputControl={
                  <>
                    <Form.Group controlId={`originalImages${index}.copyright`}>
                      <Form.Label>{t("productInfo.copyrightLabel")}*</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isProductGroup}
                        isInvalid={!!errors?.imageCopyright?.[index]}
                        value={originalImages[index].copyright || ""}
                        onChange={(e) => {
                          onChange(changeInImages("copyright", images, index, e.target.value));
                          shouldTriggerValidation && trigger("images");
                        }}
                      />
                      {errors?.imageCopyright?.[index] && (
                        <p className="text-danger">{errors.imageCopyright[index].message}</p>
                      )}
                    </Form.Group>
                    <Form.Group controlId={`originalImages${index}.altText`}>
                      <Form.Label>{t("productInfo.altTextLabel")}</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={isProductGroup}
                        isInvalid={!!errors?.altText?.[index]}
                        value={originalImages[index].altText || ""}
                        onChange={(e) =>
                          onChange(changeInImages("altText", originalImages, index, e.target.value))
                        }
                      />
                      {errors?.altText?.[index] && (
                        <p className="text-danger">{errors.altText[index].message}</p>
                      )}
                    </Form.Group>
                  </>
                }
              />
              <div className="row mb-3">
                <div className="col-5 col-md-3 pr-0 color-gray-600 text-small">
                  {(image.originalWidth ?? false) && (
                    <>
                      {t("productInfo.uploadedImageDimensions", {
                        width: image.originalWidth,
                        height: image.originalHeight,
                        orientation:
                          image.orientation === ImageOrientation.Landscape
                            ? t("productInfo.orientationLandscape")
                            : t("productInfo.orientationPortrait"),
                      })}
                    </>
                  )}
                </div>
                {index === 0 && (
                  <>
                    <div className="col ml-0 ml-md-2 color-gray-600 text-small">
                      <div className="d-flex">
                        <Icon name="questionCircle" size="medium" color="primary-dark" />
                        <DummyLink
                          className="ml-2 color-primary-dark"
                          onClick={() => setAltModal(!altModal)}
                        >
                          {t("altTextGuideline.openInstructions")}
                        </DummyLink>
                      </div>
                    </div>
                    {altModal && <AltTextGuidelineModal onHide={() => setAltModal(false)} />}
                  </>
                )}
              </div>
              {index + 1 !== images.length && <ImageDivider />}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ProductThumbnails;
