import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAsyncDebounce, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";

import { UserProfile } from "../graphqlQueries/getUserProfiles";
import { Company } from "../types/companyTypes";
import { SEARCH_DEBOUNCE_MS } from "../utils/constants";
import Icon from "./Icon";
import { TABLE_PAGE_SIZE } from "../utils/pagingUtils";
import { CustomPagination } from "./CustomPagination";

type UserTableData = {
  createdTimestamp: string | undefined;
  username: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  requestedGroup: string | undefined;
  businessId: string | undefined;
  businessName: string | undefined;
};

type UserTableProps = {
  users: UserProfile[];
  companies: Company[];
};

type FooterProps = {
  data: UserTableData[];
  globalFilteredRows: {
    original: UserTableData;
  }[];
};

const compareIgnoreCase = (val1: string, val2: string) => {
  return val1.toLowerCase().localeCompare(val2.toLowerCase());
};

const UserTable = ({ users, companies }: UserTableProps) => {
  const userData: UserTableData[] = useMemo(() => {
    const companyIdNameMap = companies.reduce((map, obj) => {
      map[obj.id] = obj.businessName;
      return map;
    }, {} as Record<string, string>);

    const companyNames = (userCompanies: string[]) => {
      const companyNames = userCompanies.reduce((arr, id) => {
        const businessName = companyIdNameMap[id];
        if (businessName) {
          arr.push(businessName);
        }
        return arr;
      }, [] as string[]);

      return companyNames.join(",\n");
    };

    return (
      users.map((user) => ({
        createdTimestamp: user.createdTimestamp,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        requestedGroup: user.requestedGroup?.toUpperCase(),
        businessId: user.businessId,
        businessName: companyNames(user.companyIds),
      })) || []
    );
  }, [users, companies]);

  const { t } = useTranslation();

  const onSearchChange = useAsyncDebounce((value: string) => {
    setGlobalFilter(value);
  }, SEARCH_DEBOUNCE_MS);

  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    onSearchChange(searchValue);
  }, [searchValue, onSearchChange]);

  const columns = useMemo(() => {
    return [
      {
        Header: t("userTable.usernameHeader"),
        accessor: "username" as const,
        Footer: (props: FooterProps): string => {
          return t("userTable.totalFooter", { count: props.globalFilteredRows.length });
        },
      },
      {
        Header: t("userTable.firstNameHeader"),
        accessor: "firstName" as const,
      },
      {
        Header: t("userTable.lastNameHeader"),
        accessor: "lastName" as const,
      },
      {
        Header: t("userTable.requestedGroupHeader"),
        accessor: "requestedGroup" as const,
      },
      {
        Header: t("userTable.businessNameHeader"),
        accessor: "businessName" as const,
      },
      {
        Header: t("userTable.businessIdHeader"),
        accessor: "businessId" as const,
      },
      {
        Header: t("userTable.joinedHeader"),
        accessor: "createdTimestamp" as const,
        Cell: (props: { value: string }) => {
          return moment(props.value).format("D.M.YYYY HH:mm");
        },
      },
    ];
  }, [t]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    footerGroups,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<UserTableData>(
    {
      columns,
      data: userData,
      initialState: {
        pageIndex: 0,
        pageSize: TABLE_PAGE_SIZE,
        sortBy: [
          {
            id: "businessName",
            desc: false,
          },
        ],
      },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
        },
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Table striped bordered hover responsive {...getTableProps()}>
      <thead>
        <tr>
          <th colSpan={7} className="bg-white">
            <div className="d-flex">
              <Form.Group controlId="user-search" className="d-flex align-items-center w-50 m-0">
                <Form.Label className="m-0 mr-3">{t("userTable.searchLabel")}</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                  placeholder={t("userTable.searchPlaceholder", {
                    count: preGlobalFilteredRows.length,
                  })}
                  value={searchValue}
                />
              </Form.Group>
              <Button
                variant="light ml-4"
                onClick={() => {
                  setSearchValue("");
                }}
              >
                {t("userTable.clearButtonLabel")}
              </Button>
            </div>
          </th>
        </tr>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(
                  column.getSortByToggleProps({ style: { whiteSpace: "nowrap" } })
                )}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <Icon name="chevron-down" />
                    ) : (
                      <Icon name="chevron-up" />
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.length === 0 && (
          <tr className="bg-white">
            <td colSpan={7}>{t("userTable.noResults")}</td>
          </tr>
        )}
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td style={{ whiteSpace: "pre" }} {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        {footerGroups.map((group) => (
          <tr {...group.getFooterGroupProps()}>
            {group.headers.map((column) => (
              <td {...column.getFooterProps()}>
                <strong>{column.render("Footer")}</strong>
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <th colSpan={7}>
            <CustomPagination
              pageAmount={pageSize}
              pageShowAmountOption={(arg) => setPageSize(arg.value)}
              pageIndicator={t("companyTable.pageIndicator", {
                pageIndex: pageIndex + 1,
                pageCount,
              })}
              toFirstPage={() => gotoPage(0)}
              toPreviousPage={previousPage}
              toNextPage={nextPage}
              toLastPage={() => gotoPage(pageCount - 1)}
              disableNext={!canNextPage}
              disablePrevious={!canPreviousPage}
            />
          </th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default UserTable;
