import { useKeycloak } from "@react-keycloak/web";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

import LanguageDropdown from "../components/LanguageDropdown";
import { ReactComponent as List } from "../icons/list.svg";
import logo from "../images/datahub-logo.svg";
import Icon from "./Icon";
import IconButton from "./IconButton";
import Separator from "./Separator";

const publicNavItems = [
  {
    to: "/",
    labelKey: "header.home",
  },
  {
    to: "/demo",
    labelKey: "header.demo",
  },
];

const PublicHeaderContainer = styled.header`
  max-width: 1080px;
  margin: 0 auto;
  height: 65px;
`;

const MobileMenu = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-white);
  z-index: 10000;
  display: flex;
  justify-content: flex-end;
`;

const MobileNavContainer = styled.div`
  width: 100vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
`;

const NavItemsContainer = styled.div`
  margin-left: -1rem;
`;

declare const window: Window & { datalayer: Record<string, unknown>[] };
const SSOBase = process.env.REACT_APP_SSO_SERVER_URL;
const SSOId = process.env.REACT_APP_SSO_SERVER_ID;
const SSOUrl = `${SSOBase}?serviceId=${SSOId}`;
const showLogin = process.env.REACT_APP_SHOW_KEYCLOAK_LOGIN === "true";

const PublicHeader = () => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { initialized, keycloak } = useKeycloak();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const acrValues = searchParams.get("acr_values");

    if (acrValues) {
      keycloak?.login({ idpHint: "ubi-saml" });
    }
  }, [location.search, keycloak]);

  const goToLogin = () => keycloak?.login({ locale: i18n.language });

  const handleBfIdLogin = () => {
    window.datalayer?.push({ event: "authenticate" });
    window.location.href = SSOUrl;
  };

  return (
    <PublicHeaderContainer>
      <div className="pl-3 pr-lg-3 d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <NavLink to="/">
            <img style={{ height: "40px" }} alt="Visit Finland data hub" src={logo} />
          </NavLink>

          <div className="d-lg-flex d-none m-0 px-0 px-md-3 ml-md-5 font-heavy justify-content-between">
            {publicNavItems.map((item) => (
              <NavLink
                exact
                key={item.to}
                to={item.to}
                activeClassName="public-nav-link-active"
                className="primary-nav-link nav-link font-heavy ml-md-3"
              >
                <span className="link-text">{t(item.labelKey)}</span>
              </NavLink>
            ))}
            <Link className="primary-nav-link nav-link font-heavy ml-md-3" to="/faq">
              {t("header.faq")}
            </Link>
          </div>
        </div>

        <div className="d-flex">
          <LanguageDropdown />
          {initialized && (
            <div className="d-none d-lg-flex">
              {showLogin && (
                <Button variant="outline-primary" className="ml-3 my-auto" onClick={goToLogin}>
                  {t("common.login")}
                </Button>
              )}
              <Button variant="primary" className="ml-3 my-auto px-2" onClick={handleBfIdLogin}>
                {t("common.loginBFID")}
              </Button>
            </div>
          )}
          <Button
            variant="secondary"
            className="d-lg-none"
            onClick={() => {
              setShowMobileMenu(true);
            }}
          >
            <List />
          </Button>
          {showMobileMenu && (
            <MobileMenu>
              <MobileNavContainer>
                <IconButton
                  onClick={() => {
                    setShowMobileMenu(false);
                  }}
                  variant="secondary align-self-end mt-3"
                  iconPosition="right"
                  iconElement={
                    <Icon
                      name="times"
                      size="medium"
                      color="black"
                      className="ml-2"
                      style={{ marginTop: "-3px" }}
                    />
                  }
                >
                  {t("common.close")}
                </IconButton>
                {initialized && (
                  <>
                    {showLogin && (
                      <Button
                        variant="outline-primary"
                        className="d-block mt-3"
                        onClick={goToLogin}
                      >
                        {t("common.login")}
                      </Button>
                    )}
                    <Button
                      variant="outline-primary"
                      className="ml-3 my-auto"
                      onClick={handleBfIdLogin}
                    >
                      {t("common.loginBFID")}
                    </Button>
                  </>
                )}
                <Separator />
                <NavItemsContainer>
                  {publicNavItems.map((item) => (
                    <NavLink
                      exact
                      key={item.to}
                      to={item.to}
                      onClick={() => setShowMobileMenu(false)}
                      activeClassName="nav-link-active"
                      className="primary-nav-link nav-link"
                    >
                      {t(item.labelKey)}
                    </NavLink>
                  ))}
                  <Link className="primary-nav-link nav-link font-heavy ml-md-3" to="/faq">
                    {t("header.faq")}
                  </Link>
                </NavItemsContainer>
                <Separator />
              </MobileNavContainer>
            </MobileMenu>
          )}
        </div>
      </div>
    </PublicHeaderContainer>
  );
};

export default PublicHeader;
