import { gql } from "@apollo/client";
import { Company } from "./getCompanyNames";

//TODO: This will return many ID:s when there are more than one company under one businessID.
const getCompanyId = gql`
  query GetCompanyId($businessId: String!) {
    businessEntity(where: { businessId: { _eq: $businessId } }) {
      companies {
        id
      }
    }
  }
`;

export const getCompanyByBusinessId = gql`
  query GetCompanyByBusinessId($businessId: String!) {
    businessEntity(where: { businessId: { _eq: $businessId } }) {
      companies {
        id
        officialName
        businessName
      }
    }
  }
`;

export const getCompaniesByBusinessIds = gql`
  query GetCompaniesByBusinessIds($businessIds: [String!]) {
    businessEntity(where: { businessId: { _in: $businessIds } }) {
      id
      businessId
      companies {
        id
        officialName
        businessName
      }
    }
  }
`;

export interface BusinessEntity {
  id: string;
  businessId: string;
  companies: Company[];
}

export interface CompanyByBusinessIdResult {
  businessEntity: BusinessEntity[];
}

export default getCompanyId;
