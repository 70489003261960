import {
  ProductGroup,
  ProductGroupPermissionData,
  ProductGroupProductInput,
  ProductGroupRole,
  ProductGroupStatus,
} from "../types";
import {
  EDIT_PRODUCT_GROUP,
  GROUP_ID_PARAM,
} from "../components/groupedProducts/productGroupRouteConstants";
import {
  EditProductInformation,
  ProductGroupImage,
  ProductGroupProductInformation,
} from "../graphqlQueries/productGroup/updateProductGroup";

export const getGroupStatus = (group: ProductGroup): ProductGroupStatus => {
  if (group.published) {
    return "published";
  }
  if (group.publishedAt) {
    return "edit";
  }

  return "open";
};

export const getEditPath = (rootPath: string, groupId: string) => {
  return `${rootPath}${EDIT_PRODUCT_GROUP.replace(GROUP_ID_PARAM, groupId)}`;
};

type ProductGroupVariableProps = {
  groupId: string;
  products: ProductGroupProductInput[];
  productGroup: { name: string; description: string };
  editedProductImages: ProductGroupImage[] | undefined;
  editedProductImagesFromDb: (ProductGroupImage | undefined)[] | undefined;
  editedProductDetailsFromDb: (EditProductInformation | undefined)[] | undefined;
  editedProductDetails: ProductGroupProductInformation[] | undefined;
};

export const getProductGroupVariables = ({
  groupId,
  products,
  productGroup,
  editedProductImages,
  editedProductDetailsFromDb,
  editedProductDetails,
}: ProductGroupVariableProps) => {
  return {
    id: groupId,
    // include only ids that have been edited
    editedProductInformationIds: editedProductDetailsFromDb
      ?.map(
        (it) =>
          editedProductDetails?.find((product) => product?.productId === it?.productId) && it?.id
      )
      .filter((o) => o),
    productGroup,
    products,
    editedProductDetails: editedProductDetails?.map((it) => {
      const {
        verifiedProductLink,
        verifiedWebshopLink,
        language,
        name,
        description,
        productId,
      } = it;

      const productLinkId = verifiedProductLink?.id;
      const webshopLinkId = verifiedWebshopLink?.id;

      return {
        language,
        name,
        description,
        productId,
        productGroupId: groupId,
        productLinkId,
        webshopLinkId,
      };
    }),
    editedProductImages: editedProductImages?.map((it) => ({
      productId: it.productId,
      altText: it.altText,
      copyright: it.copyright,
      coverPhoto: it.coverPhoto,
      filename: it.filename,
      largeUrl: it.largeUrl,
      originalHeight: it.originalHeight,
      originalUrl: it.originalUrl,
      originalWidth: it.originalWidth,
      productGroupId: groupId,
      thumbnailUrl: it.thumbnailUrl,
    })),
  };
};

const hasRole = (
  role: ProductGroupRole,
  data?: ProductGroupPermissionData,
  currentUserId?: string,
  companyIds?: string[]
) => {
  return data?.permissions?.some(
    (p) =>
      p.role === role &&
      (p.userId === currentUserId || companyIds?.some((id) => id === p.companyId))
  );
};

export const getProductGroupRole = (
  data?: ProductGroupPermissionData,
  currentUserId?: string,
  companyIds?: string[]
) => {
  if (hasRole(ProductGroupRole.Admin, data, currentUserId, companyIds)) {
    return ProductGroupRole.Admin;
  } else if (hasRole(ProductGroupRole.Manager, data, currentUserId, companyIds)) {
    return ProductGroupRole.Manager;
  } else if (hasRole(ProductGroupRole.Editor, data, currentUserId, companyIds)) {
    return ProductGroupRole.Editor;
  } else if (hasRole(ProductGroupRole.Viewer, data, currentUserId, companyIds)) {
    return ProductGroupRole.Viewer;
  }
  return null;
};
