import { gql } from "@apollo/client";
import { ContactDetail, PostalAddressCity } from "../types";

export const getCuratorCompaniesInAreas = gql`
  query GetCuratorCompaniesInAreas($postalCodes: [String!]) {
    company(
      where: { dmoAreas: { curationArea: { postalAreas: { postalCode: { _in: $postalCodes } } } } }
    ) {
      id
      officialName
      businessName
      postalAddresses {
        city
      }
      contactDetails {
        email
        phone
      }
    }
  }
`;

export type GetCuratorCompaniesInAreasVariables = {
  postalCodes: string[];
};

export type GetCuratorCompaniesInAreasResult = {
  company: {
    id: string;
    officialName: string;
    businessName: string;
    postalAddresses?: PostalAddressCity[];
    contactDetails?: ContactDetail[];
  }[];
};
