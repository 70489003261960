import {
  AffectedRowsResult,
  DataHubImage,
  IdResult,
  Language,
  ProductGroupInput,
  ProductGroupProductInput,
} from "../../types";
import { ProductInformation, VerifiedLink } from "../getProduct";

import { gql } from "@apollo/client";

export type UpdateProductGroupResult = {
  deleteProductGroupProduct: AffectedRowsResult;
  insertProductGroupProduct: AffectedRowsResult;
  updateProductGroupProduct: AffectedRowsResult;
  updateProductGroupByPk: IdResult & {
    name: string;
    description: string;
  };
};

export type ProductGroupProductInformation = ProductInformation & {
  id?: string;
  productId: string;
};

export type ProductGroupImage = DataHubImage & {
  id?: string;
  productGroupId: string;
  productId: string;
};

export type EditProductInformation = {
  id?: string;
  productId: string;
  productGroupId: string;
  language: Language;
  name: string;
  description: string;
  verifiedProductLink?: VerifiedLink | null;
  verifiedWebshopLink?: VerifiedLink | null;
};

export type UpdateProductGroupVariables = {
  id: string;
  addedProductImages?: ProductGroupImage[];
  productGroup: ProductGroupInput;
  products: ProductGroupProductInput[];
  editedProductDetails?: EditProductInformation[];
  editedProductImages?: ProductGroupImage[];
  editImages?: ProductGroupImage[];
  editedProductInformationIds?: (string | undefined)[] | undefined;
  newProducts?: ProductGroupProductInput[];
  removedProducts?: ProductGroupProductInput[];
};

export const updateProductGroupMutation = (
  hasAddedProductImages?: boolean,
  addedProductImages?: DataHubImage[] | undefined,
  editedProductDetailsFromDb?: (EditProductInformation | undefined)[] | undefined,
  editedProductDetails?: EditProductInformation[] | undefined,
  editedProductImagesFromDb?: (DataHubImage | undefined)[] | undefined,
  editedProductImages?: DataHubImage[] | undefined,
  removedProducts?: ProductGroupProductInput[] | undefined,
  hasAddedInformation?: boolean,
  hasEditedProductImages?: boolean,
  hasRemovedProductImages?: boolean,
  removedProductImages?: DataHubImage[] | undefined
) => {
  return gql`
    mutation UpdateProductGroup(
      $id: uuid!
      $productGroup: hasuradb_product_group_set_input!
      $newProducts: [hasuradb_product_group_product_insert_input!]!
      $editedProductInformationIds: [uuid!]
      ${
        hasAddedInformation
          ? `$editedProductDetails: [hasuradb_product_group_product_information_insert_input!]!`
          : ``
      }
      ${
        hasEditedProductImages && editedProductImages?.length !== 0 && !hasAddedProductImages
          ? `$editImages: [hasuradb_product_group_product_image_update_input!]`
          : ``
      }
      ${
        hasAddedProductImages && addedProductImages?.length !== 0
          ? `$addedProductImages: [hasuradb_product_group_product_image_insert_input!]!`
          : ``
      }
    ) {
      insertProductGroupProduct(objects: $newProducts) {
         affected_rows
      }
      
      ${editedProductDetails
        ?.map(
          (detail, index) => `
            updateProductGroupProductInformation_${index}: updateProductGroupProductInformation(
              where: { productId: { _eq: "${detail?.productId}" } }
              _set: { name: "${detail?.name}", description: "${detail?.description}", language: ${detail?.language}}
            ) {
              affected_rows
            }
          `
        )
        .join("\n")}
      
      ${
        hasEditedProductImages && !hasRemovedProductImages
          ? `${editedProductImages
              ?.map(
                (image, index) => `
            updateProductGroupProductImage_${index}: updateProductGroupProductImage(
              where: { filename: { _eq: "${image?.filename}" } }
              _set: {
                filename: "${image?.filename}",
                copyright: "${image?.copyright}",
                coverPhoto: "${image?.coverPhoto}",
                originalUrl: "${image?.originalUrl}",
                originalHeight: "${image?.originalHeight}",
                originalWidth: "${image?.originalWidth}",
                largeUrl: "${image?.largeUrl}",
                thumbnailUrl: "${image?.thumbnailUrl}",
                altText: "${image?.altText !== null ? image?.altText : null}"
              }
            ) {
              affected_rows
            }
          `
              )
              .join("\n")}`
          : ""
      }
      
      ${
        hasAddedProductImages && addedProductImages?.length !== 0
          ? `insertProductGroupProductImage(objects: $addedProductImages) {
            affected_rows
          }`
          : ""
      }
      
      ${
        hasAddedInformation
          ? `insertProductGroupProductInformation(objects: $editedProductDetails) {
            affected_rows
          }`
          : ""
      }
      
      updateProductGroupByPk(pk_columns: { id: $id }, _set: $productGroup) {
        id
        name
        description
      }
      
      ${
        hasRemovedProductImages
          ? removedProductImages
              ?.map(
                (img, index) => `
                deleteProductGroupProductImage_${index}: deleteProductGroupProductImage(
                  where: { filename: { _eq: "${img?.filename}" } }
                    ) {
                    affected_rows
                    }
                    `
              )
              .join("\n")
          : ""
      }
      
       ${removedProducts
         ?.map(
           (prod, index) => `
                deleteProductGroupProduct_${index}: deleteProductGroupProduct(
                  where: { productId: { _eq: "${prod?.productId}" } }
                ) {
                  affected_rows
                }
              `
         )
         .join("\n")}
    }
  `;
};
