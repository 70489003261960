import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FAQ_PATH } from "../utils/routeConstants";
import { breakpointPixels } from "../css/breakpoints";
import euLogo from "../images/eu_logo.png";

const FooterContainer = styled.div`
  width: 100%;
  height: 12.5rem;
  display: flex;
  align-content: center;
  padding: 1rem 2rem;
  background-color: var(--color-background);
  @media (min-width: ${breakpointPixels.xs}) {
    height: 100%;
    padding-bottom: 1rem;
  }
`;

const FooterLinksContainer = styled.div`
  max-width: 1080px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  img {
    height: 100px;
    width: auto;
  }
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: var(--color-gray-900);
  &:hover {
    color: var(--color-gray-900);
    text-decoration: underline;
  }
`;

type FooterLinkType = {
  href: string;
  label: string;
  type: "mail" | "website";
};

const Footer = () => {
  const { t } = useTranslation();
  const links: FooterLinkType[] = [
    { href: t("footer.contactLink"), label: t("footer.contactLabel"), type: "mail" },
    { href: t("footer.copyrightLink"), label: t("footer.copyrightLabel"), type: "website" },
    { href: t("footer.termsOfUseLink"), label: t("footer.termsOfUseLabel"), type: "website" },
    {
      href: t("footer.privacyStatementLink"),
      label: t("footer.privacyStatementLabel"),
      type: "website",
    },
    { href: t("footer.accessibilityLink"), label: t("footer.accessibilityLabel"), type: "website" },
    { href: FAQ_PATH, label: t("ukk.header"), type: "website" },
  ];

  return (
    <FooterContainer className="border-top">
      <FooterLinksContainer className="row mx-auto pt-3 px-lg-5 justify-content-center">
        {links.map((link) => (
          <div className="col-lg-auto" key={link.href}>
            <FooterLink
              href={link.href}
              target={link.type === "website" ? "_blank" : undefined}
              rel={link.type === "website" ? "noopener noreferrer" : undefined}
            >
              {link.label}
            </FooterLink>
          </div>
        ))}
      </FooterLinksContainer>
      <LogoContainer>
        <img alt={t("footer.euLogoAltText")} src={euLogo} />
      </LogoContainer>
    </FooterContainer>
  );
};

export default Footer;
