import React from "react";
import Thumbnail from "./Thumbnail";
import { DataHubImage } from "../types";

type ProductThumbnailProps = {
  image: DataHubImage;
  onDelete: (deleted: DataHubImage) => void;
  onClick: (clicked: DataHubImage) => void;
  error?: string;
  inputControl: React.ReactNode;
  showDelete?: boolean;
};

const ProductThumbnail = ({
  image,
  onDelete,
  onClick,
  inputControl,
  showDelete = true,
}: ProductThumbnailProps) => {
  return (
    <div className="d-flex align-items-center mb-3">
      <Thumbnail
        image={image}
        onDelete={onDelete}
        onClick={onClick}
        altText={image.copyright || ""}
        showDelete={showDelete}
      />
      <div className="flex-grow-1 ml-4">{inputControl}</div>
    </div>
  );
};

export default ProductThumbnail;
