export const ROOT_PATH = "/";
export const SIGNUP_PATH = "/signup";
export const COMPANY_PATH = "/company";
export const DASHBOARD_PATH = "/dashboard";
export const CONTACT_INFORMATION_PATH = "/contact-information";
export const PRODUCTS_PATH = "/products";
export const USER_MANAGEMENT_PATH = "/user-management";
export const API_INSTRUCTIONS_PATH = "/api-instructions";
export const COMPANIES_PATH = "/companies";
export const CURATOR_WORKSPACE_PATH = "/curator-workspace";
export const PUBLISHED_PRODUCTS_PATH = "/published-products";
export const LOGIN_PATH = "/login";
export const DEMO_PATH = "/demo";
export const ACCOUNT_PATH = "/account";
export const LOGIN_REDIRECT_PARAM = "redirect";
export const FAQ_PATH = "/faq";
export const INSTRUCTIONS_PATH = "/instructions";
export const GROUPED_PRODUCTS_PATH = "/product-groups";

// Constants for consistent route params
export const PARAM_ADD_NEW = "/add-new";
export const PARAM_EDIT_EXISTING = "/edit";
