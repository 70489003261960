import { useLazyQuery } from "@apollo/client";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { breakpointPixels } from "../../css/breakpoints";
import getCompanyDatahubType, {
  GetCompanyDatahubTypeResult,
  GetCompanyDatahubTypeVariables,
} from "../../graphqlQueries/getCompanyDatahubType";
import { RequestedGroup, UserRole } from "../../types";
import { userInfoVar } from "../../utils/ApolloCache";
import {
  getHasuraRoleContext,
  isApiUser,
  isCompanyDmo,
  isVisitFinlandAdmin,
} from "../../utils/functions";
import {
  COMPANIES_PATH,
  COMPANY_PATH,
  CONTACT_INFORMATION_PATH,
  DASHBOARD_PATH,
  GROUPED_PRODUCTS_PATH,
  USER_MANAGEMENT_PATH,
} from "../../utils/routeConstants";
import { useCompanyId } from "../hooks/useCompanyId";
import Icon from "../Icon";
import IconButton from "../IconButton";
import Separator from "../Separator";
import MenuLinkWithJoinRequestNotification from "./MenuLinkWithJoinRequestNotification";
import NavigationMenuLink from "./NavigationMenuLink";
import ProductNavLinks from "./ProductNavLinks";
import ExternalLink from "../ExternalLink";
import { useRouteMatch } from "react-router-dom";
import OrderTranslationsButton from "./OrderTranslationsButton";

type NavigationProps = {
  showOnMobile?: boolean;
  onNavClick?: () => void;
  filterComponent?: React.ReactNode;
};

type MobileCloseProps = {
  onNavClick?: () => void;
};

const MobileCloseBar = ({ onNavClick }: MobileCloseProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 mb-3 d-lg-none text-right">
      <IconButton
        onClick={() => {
          onNavClick && onNavClick();
        }}
        variant="secondary"
        iconPosition="right"
        iconElement={
          <Icon
            name="times"
            size="medium"
            color="black"
            className="ml-2"
            style={{ marginTop: "-3px" }}
          />
        }
      >
        {t("common.close")}
      </IconButton>
    </div>
  );
};

const MobileContainer = styled.nav`
  z-index: 1100;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-white);

  @media (min-width: ${breakpointPixels.sm}) {
    display: flex;
    justify-content: flex-end;
    background: rgba(52, 58, 64, 0.5);
  }

  @media (min-width: ${breakpointPixels.lg}}) {
    display: none;
  }
`;

const NavigationContainer = styled.div`
  width: 100%;
  @media (min-width: ${breakpointPixels.sm}) {
    width: var(--navigation-width);
  }
`;

type MobileWrapperProps = {
  condition: boolean;
  children: React.ReactNode;
};

const MobileWrapper = ({ condition, children }: MobileWrapperProps) =>
  condition ? <MobileContainer>{children}</MobileContainer> : <>{children}</>;

const Navigation = ({ showOnMobile = false, onNavClick, filterComponent }: NavigationProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [companyId] = useCompanyId();
  const { path } = useRouteMatch();
  const userInfo = userInfoVar();

  const hasManageCuration = keycloak.hasResourceRole(UserRole.ManageCuration);
  const hasManageCompany = keycloak.hasResourceRole(UserRole.ManageCompany);
  const hasManageProducts = keycloak.hasResourceRole(UserRole.ManageProducts);
  const hasViewOpenProducts = keycloak.hasResourceRole(UserRole.ViewOpenProducts);
  const hasViewCompanyRights =
    hasManageCompany && userInfoVar()?.requestedGroup !== RequestedGroup.B2B && companyId !== null;
  const apiUser = isApiUser(userInfo);

  const accountInfoKey = hasManageCuration
    ? "navigation.organisationInfo"
    : "navigation.companyInfo";

  const [getCompanyDatahubTypeQuery, { data: companyData }] = useLazyQuery<
    GetCompanyDatahubTypeResult,
    GetCompanyDatahubTypeVariables
  >(getCompanyDatahubType, {
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (companyId) {
      getCompanyDatahubTypeQuery({
        variables: {
          companyId: companyId,
        },
        context: getHasuraRoleContext(UserRole.ViewCompany),
      });
    }
  }, [getCompanyDatahubTypeQuery, companyId]);

  const canViewProducts =
    hasViewOpenProducts ||
    hasManageProducts ||
    hasViewCompanyRights ||
    (hasManageCuration && isCompanyDmo(companyData?.companyByPk?.datahubType));

  const canViewProductGroups =
    (hasViewOpenProducts && !apiUser) ||
    hasManageProducts ||
    hasViewCompanyRights ||
    (hasManageCuration && isCompanyDmo(companyData?.companyByPk?.datahubType));

  return (
    <MobileWrapper condition={showOnMobile}>
      <div className="py-3 bg-white" onClick={onNavClick} role="button" onKeyPress={onNavClick}>
        <MobileCloseBar />
        <NavigationContainer role="tablist">
          {!isVisitFinlandAdmin(keycloak) ? (
            <NavigationMenuLink
              to={companyId ? `${COMPANY_PATH}/${companyId}${DASHBOARD_PATH}` : DASHBOARD_PATH}
              i18n="navigation.dashboard"
              icon="home"
            />
          ) : (
            <MenuLinkWithJoinRequestNotification
              to={companyId ? `${COMPANY_PATH}/${companyId}${DASHBOARD_PATH}` : DASHBOARD_PATH}
              i18n="navigation.dashboard"
              tabIcon="home"
              vfadmin
            />
          )}
          <Separator leftMarginRem={1} rightMarginRem={1} topMarginRem={1} bottomMarginRem={1} />
          {canViewProducts && (
            <ProductNavLinks
              hasViewOpenProducts={hasViewOpenProducts}
              hasManageProducts={hasManageProducts}
              isCurator={hasManageCuration && isCompanyDmo(companyData?.companyByPk?.datahubType)}
              companyId={companyId}
              filterComponent={filterComponent}
            />
          )}
          {canViewProductGroups && (
            <NavigationMenuLink
              to={
                companyId
                  ? `${COMPANY_PATH}/${companyId}${GROUPED_PRODUCTS_PATH}`
                  : GROUPED_PRODUCTS_PATH
              }
              i18n="navigation.groupedProducts"
            />
          )}
          {hasManageCuration && isCompanyDmo(companyData?.companyByPk?.datahubType) && (
            <NavigationMenuLink
              to={companyId ? `${COMPANY_PATH}/${companyId}${COMPANIES_PATH}` : COMPANIES_PATH}
              i18n="navigation.companies"
            />
          )}
          {hasViewCompanyRights && (
            <>
              <Separator
                leftMarginRem={1}
                rightMarginRem={1}
                topMarginRem={1}
                bottomMarginRem={1}
              />
              <NavigationMenuLink
                to={{
                  pathname: `${COMPANY_PATH}/${companyId}${CONTACT_INFORMATION_PATH}`,
                  state: { previousPath: path },
                }}
                i18n={accountInfoKey}
              />

              <MenuLinkWithJoinRequestNotification
                to={`${COMPANY_PATH}/${companyId}${USER_MANAGEMENT_PATH}`}
                i18n="navigation.userManagement"
                companyId={companyId}
              />
            </>
          )}
          <>
            <Separator leftMarginRem={1} rightMarginRem={1} topMarginRem={1} bottomMarginRem={1} />
            <ExternalLink className="primary-nav-link nav-link color-primary-dark" href="/faq">
              {t("ukk.header")}
            </ExternalLink>
            {!isApiUser(userInfo) && <OrderTranslationsButton />}
          </>
        </NavigationContainer>
      </div>
    </MobileWrapper>
  );
};

export default Navigation;
