import React from "react";
import logo from "../../images/datahub-logo.svg";
import styled from "styled-components";
import LanguageDropdown from "../LanguageDropdown";
import bgImage from "../../images/pallas_background_1920.jpg";
import { breakpointPixels } from "../../css/breakpoints";
import { Button } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";

const BgContainer = styled.div`
  @media (min-width: ${breakpointPixels.sm}) {
    position: fixed;
    background: url(${bgImage}) no-repeat center center fixed;
    background-size: cover;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: var(--header-height);
`;

const ContentContainer = styled.main`
  @media (min-width: ${breakpointPixels.sm}) {
    max-width: 550px;
    padding: 48px 88px;
    box-shadow: 1px 2px 11px 0 rgba(0, 0, 0, 0.23);
    height: 100%;
    min-height: 60vh;
    overflow-y: auto;
  }
  padding: 0 30px;
  width: 100%;
  height: 100%;
  background: var(--color-white);
`;

const HeaderContainer = styled.header`
  max-width: 1080px;
  margin: 0 auto;
  height: var(--header-height);
`;

export const Container = ({ children }: { children: React.ReactNode }) => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  return (
    <div className="w-100">
      <div className="sticky-top border-bottom bg-white">
        <HeaderContainer className="d-flex justify-content-between align-items-center px-2">
          <img style={{ height: "40px" }} alt="Visit Finland data hub" src={logo} />
          <div className="d-flex">
            <LanguageDropdown />
            <Button
              className="ml-1 pr-0"
              variant="link"
              onClick={() => {
                keycloak?.logout();
              }}
            >
              {t("common.logout")}
            </Button>
          </div>
        </HeaderContainer>
      </div>
      <BgContainer>
        <div className="d-flex justify-content-center h-100">
          <div className="my-5">
            <ContentContainer>{children}</ContentContainer>
          </div>
        </div>
      </BgContainer>
    </div>
  );
};
