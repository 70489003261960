import {
  ACCOUNT_PATH,
  API_INSTRUCTIONS_PATH,
  COMPANIES_PATH,
  COMPANY_PATH,
  CONTACT_INFORMATION_PATH,
  CURATOR_WORKSPACE_PATH,
  DASHBOARD_PATH,
  GROUPED_PRODUCTS_PATH,
  PRODUCTS_PATH,
  PUBLISHED_PRODUCTS_PATH,
  ROOT_PATH,
  SIGNUP_PATH,
  USER_MANAGEMENT_PATH,
} from "../utils/routeConstants";
import React, { useEffect } from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { isCompanyAllowedForUser } from "../utils/functions";

import APIInstructions from "../pages/APIInstructions";
import Companies from "../pages/Companies";
import CompanyInformation from "../pages/CompanyInformation";
import CuratorWorkspace from "../pages/CuratorWorkspace";
import Dashboard from "../pages/Dashboard";
import GroupedProducts from "../pages/GroupedProducts";
import Loading from "./Loading";
import Products from "../pages/Products";
import PublishedProducts from "../pages/PublishedProducts";
import SignUp from "../pages/SignUpWizard/SignUp";
import UserManagement from "../pages/UserManagement";
import { useCompanyId } from "./hooks/useCompanyId";
import { userInfoVar } from "../utils/ApolloCache";
import { MaintenanceCtxProvider } from "../context/MaintenanceContext";
import { SelectAccount } from "./accountSelection/SelectAccount";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

interface PrivateRouteProps extends RouteProps {
  authenticated: boolean;
  altRedirectPath?: string;
  altRedirectCondition?: () => boolean;
}

const PrivateRoute = ({
  authenticated,
  altRedirectCondition,
  altRedirectPath,
  children,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const altRedir = altRedirectCondition && altRedirectCondition();

        if (authenticated && !altRedir) {
          return children;
        }

        const redirPath = altRedirectPath && altRedir ? altRedirectPath : "/";

        return (
          <Redirect
            to={{
              pathname: redirPath,
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

type CompanySwitchProps = {
  companyId: string;
};

const CompanySwitch = ({ companyId }: CompanySwitchProps) => {
  const { path } = useRouteMatch();
  const [, setCompanyId] = useCompanyId();

  const resolveCompanyId = (urlCompanyId: string | undefined, storedCompanyId: string) => {
    if (!urlCompanyId || urlCompanyId === storedCompanyId) {
      return storedCompanyId;
    } else {
      const usersCompanies = userInfoVar()?.companyIds;

      return isCompanyAllowedForUser(usersCompanies, urlCompanyId)
        ? setCompanyId(urlCompanyId)
        : storedCompanyId;
    }
  };

  const urlCompanyId = useRouteMatch<{ companyId: string }>(`${path}/:companyId`)?.params;
  const activeCompanyId = resolveCompanyId(urlCompanyId?.companyId, companyId);

  return (
    <Switch>
      <Route path={`${path}/${activeCompanyId}${CONTACT_INFORMATION_PATH}`}>
        <CompanyInformation companyId={activeCompanyId} />
      </Route>
      <Route path={`${path}/${activeCompanyId}${PRODUCTS_PATH}`}>
        <Products companyId={activeCompanyId} />
      </Route>
      <Route path={`${path}/${activeCompanyId}${GROUPED_PRODUCTS_PATH}`}>
        <GroupedProducts />
      </Route>
      <Route path={`${path}/${activeCompanyId}${DASHBOARD_PATH}`}>
        <Dashboard companyId={activeCompanyId} />
      </Route>
      <Route path={`${path}/${activeCompanyId}${USER_MANAGEMENT_PATH}`}>
        <UserManagement companyId={activeCompanyId} />
      </Route>
      <Route path={`${path}/${activeCompanyId}${API_INSTRUCTIONS_PATH}`}>
        <APIInstructions />
      </Route>
      <Route path={`${path}/${activeCompanyId}${COMPANIES_PATH}`}>
        <Companies companyId={activeCompanyId} />
      </Route>
      <Route path={`${path}/${activeCompanyId}${CURATOR_WORKSPACE_PATH}`}>
        <CuratorWorkspace companyId={activeCompanyId} />
      </Route>
      <Route path={`${path}/${activeCompanyId}${PUBLISHED_PRODUCTS_PATH}`}>
        <PublishedProducts />
      </Route>
      <Route
        path={ROOT_PATH}
        render={(routeProps) => {
          return (
            <Redirect
              to={{
                pathname: `${path}/${activeCompanyId}${DASHBOARD_PATH}`,
                state: { from: routeProps.location },
              }}
            />
          );
        }}
      />
    </Switch>
  );
};

const B2bSwitch = () => {
  return (
    <Switch>
      <Route path={DASHBOARD_PATH}>
        <Dashboard />
      </Route>
      <Route path={API_INSTRUCTIONS_PATH}>
        <APIInstructions />
      </Route>
      <Route path={GROUPED_PRODUCTS_PATH}>
        <GroupedProducts />
      </Route>
      <Route path={PUBLISHED_PRODUCTS_PATH}>
        <PublishedProducts />
      </Route>
      <Route
        path={ROOT_PATH}
        render={(routeProps) => {
          return (
            <Redirect
              to={{
                pathname: DASHBOARD_PATH,
                state: { from: routeProps.location },
              }}
            />
          );
        }}
      />
    </Switch>
  );
};

type RoutesProps = {
  authenticated: boolean;
  hasCompany: boolean;
  companyRequired: boolean;
  companyId: string;
  hasJoinRequests: boolean;
  isApiUser: boolean;
  isAdminUser: boolean;
};

const Routes = ({
  authenticated,
  hasCompany,
  companyRequired,
  companyId,
  hasJoinRequests,
  isApiUser,
  isAdminUser,
}: RoutesProps) => {
  const signupRedirect = companyRequired && !hasCompany && !hasJoinRequests;

  return (
    <MaintenanceCtxProvider>
      <main>
        <ScrollToTop />
        <Switch>
          <Route exact path={SIGNUP_PATH}>
            <SignUp />
          </Route>
          <Route exact path={ACCOUNT_PATH}>
            <SelectAccount />
          </Route>
          {companyRequired && companyId && (
            <PrivateRoute authenticated={authenticated} path={COMPANY_PATH}>
              <CompanySwitch companyId={companyId} />
            </PrivateRoute>
          )}
          {!hasCompany && !companyRequired && isApiUser && (
            <PrivateRoute authenticated={authenticated} path={ROOT_PATH}>
              <B2bSwitch />
            </PrivateRoute>
          )}
          <Route
            path={ROOT_PATH}
            render={(routeProps) => {
              if (authenticated && hasCompany && !isAdminUser) {
                return (
                  <Redirect
                    to={{
                      pathname: ACCOUNT_PATH,
                      state: { from: routeProps.location },
                    }}
                  />
                );
              } else if (authenticated && signupRedirect && !isAdminUser) {
                return (
                  <Redirect
                    to={{
                      pathname: ACCOUNT_PATH,
                      state: { from: routeProps.location },
                    }}
                  />
                );
              } else if (authenticated && !hasCompany && !isAdminUser) {
                return (
                  <Redirect
                    to={{
                      pathname: ACCOUNT_PATH,
                      state: { from: routeProps.location },
                    }}
                  />
                );
              } else if (authenticated && isAdminUser) {
                return (
                  <Redirect
                    to={{
                      pathname: COMPANY_PATH,
                      state: { from: routeProps.location },
                    }}
                  />
                );
              }
              return <Loading />;
            }}
          />
        </Switch>
      </main>
    </MaintenanceCtxProvider>
  );
};

export default Routes;
