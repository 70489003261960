import React, { RefObject } from "react";
import { ApolloClient } from "@apollo/client";
import { CuratorProduct } from "./types/curatorProductTypes";
import { HasuradbCompanyProduct } from "./graphqlQueries/getCompanyProducts";
import {
  HasuradbDeletedProduct,
  HasuradbProduct,
  ProductInformation,
} from "./graphqlQueries/getProduct";
import type { KeycloakTokenParsed } from "keycloak-js";
import { Moment } from "moment";
import { DeepMap, NestedValue } from "react-hook-form";
import { NotificationType } from "./components/Notification";

export enum RequestedGroup {
  B2B = "b2b",
  TC = "tc",
  DMO = "dmo",
}

export enum ActionResultResponse {
  RESULT_OK = "ok",
  GENERAL_ERROR = "error",
  LENGTH_ERROR = "length_error",
  LENGTH_ERROR_MESSAGE = "comment_length_check",
  NO_RESULT = "noresult",
}

export enum RoleGroups {
  B2BUsers = "/B2B Users",
  TCUsers = "/Travel Company Users",
  TCAdmins = "/Travel Company Admins",
  DMOUsers = "/Regional Users",
  DMOAdmins = "/Regional Admins",
  VFUsers = "/Visit Finland Users",
  VFAdmins = "/Visit Finland Admins",
  APIUsers = "/API Users",
}

export interface UserInfo {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  businessId?: string;
  requestedGroup?: RequestedGroup;
  companyIds?: string[];
  userId: string;
  roleGroups?: RoleGroups[];
  email?: string;
}

export type LoginCompanyAccount = {
  companyName: string;
  phone: string;
  email: string;
  streetName: string;
  postalCode: string;
  city: string;
  cities: SelectOption[];
};

export interface DatahubAccessToken extends KeycloakTokenParsed {
  sub: string;
  name?: string;
  business_id?: string;
  given_name?: string;
  family_name?: string;
  requested_group?: RequestedGroup;
  company_ids?: string[];
  resource_access: {
    account: {
      roles: Array<string>;
    };
    "datahub-app": {
      roles: Array<string>;
    };
  };
  groups?: RoleGroups[];
  email?: string;
}

export interface KeycloakTokenWithGroups extends KeycloakTokenParsed {
  groups: string[];
}

export enum UserType {
  TravelCompany = "TravelCompany",
  DestinationManagingOrganisation = "DestinationManagingOrganisation",
}

export type CompanyInformation = {
  companyName: string;
  businessId: string;
  companyId?: string;
};

export type CompanyInformationValues = BusinessHoursFormValues & {
  businessName: string;
  description: string;
  images?: DataHubImage[];
  streetName: string;
  postalCode: string;
  city: string;
  website: string;
  webshop: string;
  email: string;
  phonenumber: string;
  businessId: string;
  companyUuid: string;
  officialName: string;
  cities?: SelectOption[];
  businessHoursId: string;
  businessHoursEnabled: boolean;
  socialMediaLinks?: ProductSocialMediaLink[];
  companySocialMediaId: string;
};

export enum ImageOrientation {
  Landscape = "landscape",
  Portrait = "portrait",
}

export type IconElementPosition = "left" | "right";

export type DataHubImage = {
  id?: string;
  productGroupId?: string;
  productId?: string;
  filename: string;
  originalUrl: string;
  largeUrl: string;
  thumbnailUrl: string;
  originalWidth?: number;
  originalHeight?: number;
  orientation?: ImageOrientation;
  copyright?: string;
  altText?: string;
  coverPhoto?: boolean;
};

export enum ImageUploadErrorEnum {
  TooBigFile = "TooBigFile",
  UploadFailed = "UploadFailed",
  TooSmallDimensions = "TooSmallDimensions",
  TooSmallLogoDimensions = "TooSmallLogoDimensions",
  TooManyFiles = "TooManyFiles",
}

export enum ImageEndpoint {
  CompanyLogoEndpoint = "images/company",
  ProductImageEndpoint = "images/product",
}

export type ImageUploadError = {
  type: ImageUploadErrorEnum;
  variables?: {
    [key: string]: string;
  };
};

export type ImageUploadResult = {
  uploadedImages: DataHubImage[];
  invalidImages: InvalidImages[];
};

export type ImageDropProps = {
  images: DataHubImage[];
  onChange: (images: DataHubImage[]) => void;
  fileValidator: (file: FileWithSize) => ImageUploadError | undefined;
  uploadError: ImageUploadError[];
  setUploadError: React.Dispatch<React.SetStateAction<ImageUploadError[]>>;
  imageRef?: RefObject<HTMLInputElement>;
};

type InvalidImages = {
  status: string;
  reason: {
    error: string;
  };
};

export type ImageValidationResult = ImageUploadError | FileWithSize;

export type InvalidImageReasonJson = {
  message: string;
  name: "ValidationError";
  type: string;
  value: {
    originalname: string;
    height: number;
    width: number;
  };
};

export type AllPossibleProductQueryResult =
  | CuratorProduct
  | HasuradbProduct
  | HasuradbCompanyProduct;

export enum ProductType {
  Accommodation = "accommodation",
  Attraction = "attraction",
  Experience = "experience",
  Event = "event",
  RentalService = "rental_service",
  Restaurant = "restaurant",
  Shop = "shop",
  Venue = "venue",
  Transportation = "transportation",
}

export enum TransportationAvailabilityEnum {
  BusinessHoursOnly = "businessHoursOnly",
  OrderOnly = "orderOnly",
  OrderAndBusinessHours = "orderAndBusinessHours",
}

export enum ProductTargetGroup {
  BTOC = "b2c",
  BTOB = "b2b",
}

export type ProductCategory = {
  value: string;
  label: string | React.ReactNode;
};

export type SelectOption = {
  value: string;
  label: string;
};

export type ExternalSource = {
  value: string;
  label: string;
};

export type CategoryGroup = {
  label: string;
  groupName: string;
  children: SelectOption[];
  className: string;
};

export enum AccessibilityType {
  Accessible = "Accessible",
  NotAccessible = "NotAccessible",
  Undefined = "Undefined",
}

export enum Certificate {
  STF = "stf_certificate",
  WelcomeCyclist = "welcome_cyclist_certificate",
}

export enum AvailabilitySeason {
  AllYear = "AllYear",
  SpecificMonths = "SpecificMonths",
  NotSet = "NotSet",
}

export enum Month {
  January = "january",
  February = "february",
  March = "march",
  April = "april",
  May = "may",
  June = "june",
  July = "july",
  August = "august",
  September = "september",
  October = "october",
  November = "november",
  December = "december",
}

export type AvailableMonth = {
  month: string;
};

export type FileWithSize = {
  width: number;
  height: number;
  file: File;
};

export enum DurationType {
  Hours = "hours",
  Days = "days",
  Minutes = "minutes",
  Weeks = "weeks",
}

export enum PricingUnit {
  Hour = "hour",
  Day = "day",
  Person = "person",
  Week = "week",
  Placeholder = "select",
}

export type ProductOpeningHours = OpeningHoursInputFormValues & {
  weekday: Weekday;
};

export type ProductDetails = {
  language: Language;
  productName: string;
  productDescription: string;
  productLink: ProductFormLink;
  webshopLink: ProductFormLink;
};

export type dirtyProps = {
  dirty: DeepMap<MyProductFormValues, true>;
  initial?: ProductInformation[];
  current?: ProductDetails[];
};

export type ProductSocialMediaLink = {
  linkType: SocialMediaPlatform;
  verifiedLink: ProductFormLink;
};

export type CompanySocialMediaLinkOut = ProductSocialMediaLink & {
  companyId: string;
};

export type ProductVideo = {
  title: string;
  videoLink: ProductFormLink;
};

export enum Weekday {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export type AvailabilityLanguage = { value: Language; label?: string };

export type OpeningHoursWeek = {
  [key in Weekday]?: ProductOpeningHours;
};

export type OpeningHoursDates = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
};

export type OpeningHoursExceptionFormValues = {
  period: NestedValue<OpeningHoursDates>;
  openingHours?: OpeningHoursWeek;
};

export type BusinessHoursFormValues = {
  openingHours: OpeningHoursWeek;
  openingHoursExceptions?: OpeningHoursExceptionFormValues[];
};

export type MyProductFormValues = BusinessHoursFormValues & {
  details: ProductDetails[];
  originalDetails: ProductDetails[];
  productType: ProductType;
  productCategories?: ProductCategory[];
  productSocialMediaLinks?: ProductSocialMediaLink[];
  targetGroups: ProductTargetGroup[];
  images: DataHubImage[];
  originalImages: DataHubImage[];
  phonenumber: string;
  email: string;
  availabilitySeason: AvailabilitySeason;
  specificMonths: Month[];
  eventDateRange: {
    startDate: Moment | null;
    endDate: Moment | null;
  };
  availabilityLanguages: AvailabilityLanguage[];
  priceFree: boolean;
  priceFrom: string | null;
  priceTo: string | null;
  city: string;
  pricingUnit?: { value: PricingUnit };
  streetName: string;
  postalCode: string;
  minutes?: { label: string; value: number };
  hours?: { label: string; value: number };
  days?: { label: string; value: number };
  weeks?: { label: string; value: number };
  longitude?: string;
  latitude?: string;
  videos: ProductVideo[];
  stfCertified: boolean;
  welcomeCyclistCertified: boolean;
  accessible: AccessibilityType;
  capacityMin: string | null;
  capacityMax: string | null;
  businessHoursId: string;
  socialMediaId: string;
  companySocialMediaId: string;
  companySocialMediaEnabled: boolean;
  companyBusinessHoursId: string;
  companyHoursEnabled: boolean;
  transportationAvailability: TransportationAvailabilityEnum | null;
};

export type CuratorEditProductFormValues = MyProductFormValues & {
  curatorComment: string;
};

export enum UserRole {
  ViewOpenProducts = "view-open-products",
  ViewProducts = "view-products",
  ManageProducts = "manage-products",
  CreateCompany = "create-company",
  ViewCompany = "view-company",
  ManageCompany = "manage-company",
  ManageCuration = "manage-curation",
  ManageDatahub = "manage-datahub",
  ViewNews = "view-news",
  ManageProductGroup = "manage-product-group",
  CurateAllAreas = "manage-curation-all-areas",
}

export type ProductUserRole =
  | UserRole.ViewOpenProducts
  | UserRole.ManageProducts
  | UserRole.ManageCuration;

export type ProductCommentsUserRole = UserRole.ManageProducts | UserRole.ManageCuration;

export type AggregateCount = {
  aggregate: {
    count: number;
  };
};

export type CommentListHeaderTextKeys = {
  header: string;
  viewAll: string;
  viewNewest: string;
};

export type CommentListTextKeys = {
  loading: string;
  errorLoading: string;
  noComments: string;
  showMore: string;
  commentListHeader: CommentListHeaderTextKeys;
  commentNotSeen: string;
  commentNew: string;
  edit?: string;
};

export type SimpleModalTextKeys = {
  close: string;
  errorHeader: string;
  errorDescription: string;
  okHeader: string;
  okDescription: string;
};

export type AddCommentFormTextKeys = {
  formHeader: string;
  commentHint: string;
  send: string;
  clear: string;
  leavePrompt: string;
};

export type CommentToEdit = {
  id: string;
  comment: string;
};
export type CurationStatuses =
  | CurationStatusesFilter.ApprovedAndUpdated
  | CurationStatusesFilter.Approved
  | CurationStatusesFilter.New
  | CurationStatusesFilter.Updated
  | CurationStatusesFilter.Pending
  | CurationStatusesFilter.All;

export type CompanyProductStatuses =
  | CurationStatusesFilter.Draft
  | CurationStatusesFilter.Approved
  | CurationStatusesFilter.CurationPending
  | CurationStatusesFilter.AllCompanyProducts;

export type ProductFilters = {
  search?: string;
  productType?: string;
  productCategories?: string[];
  months?: string[];
  targetGroups?: string[];
  languages?: Language[];
  byAllLanguagesFilter?: boolean;
  cities?: string[];
  curationStatus?: CurationStatusesFilter;
  withoutCurator?: boolean;
  accessibleFilter?: boolean;
  stfCertifiedFilter?: boolean;
  productNotification?: string;
  externalSource?: string;
  tcCompanyIds?: string[];
};

export type StatusFilterOption = {
  value: CurationStatusesFilter;
  label: string;
};

export enum FilterType {
  DesktopFilters = "DesktopFilters",
  MobileFilters = "MobileFilters",
}

export enum FilterMode {
  CuratorWorkspace = "CuratorWorkspace",
  PublishedProducts = "PublishedProducts",
  VFAdminCuratorWorkspace = "VFAdminCuratorWorkspace",
  CompanyProducts = "CompanyProducts",
}

export const initialFilters: ProductFilters = {
  stfCertifiedFilter: false,
  accessibleFilter: false,
  byAllLanguagesFilter: false,
};

export type ViewMode = "company" | "dmo" | "publishedProducts";

export type RoleType = "company" | "user";

export enum CurationStatusesFilter {
  New = "new",
  Updated = "updated",
  Pending = "pending",
  Approved = "approved",
  ApprovedAndUpdated = "approvedAndUpdated",
  All = "all",
  Draft = "draft",
  CurationPending = "curationPending",
  AllCompanyProducts = "allCompanyProducts",
}

export enum ProductNotificationFilter {
  BrokenLink = "brokenLink",
  NewComment = "newComment",
}

export enum ProductCurationStatus {
  New = "new",
  Updated = "updated",
  ChangesRequested = "changes_requested",
  Complete = "complete",
}

export enum CurationStatus {
  Pending = "pending",
  Approved = "approved",
}

export enum ProductStatus {
  Published = "published",
  Draft = "draft",
}

export enum OrderBy {
  Ascending = "asc",
  Descending = "desc",
}

export type NotificationStatus = "ok" | "error";

export type Language =
  | "en"
  | "fi"
  | "sv"
  | "de"
  | "zh"
  | "ru"
  | "ja"
  | "fr"
  | "es"
  | "ko"
  | "it"
  | "ar"
  | "nl"
  | "et"
  | "no"
  | "pl"
  | "pt"
  | "da";

export type ProductPublishMode = "draft" | "publish" | "update" | "product-group";

export type ProductPublishResult = "noresult" | "ok" | "error";

export type ActionResult = "ok" | "error" | "noresult" | "length_error";

export enum DatahubType {
  DMO = "dmo",
  TC = "tc",
}

export enum ExternalProductSource {
  Bokun = "bokun",
  Johku = "johku",
}

export type Location = {
  latitude: number;
  longitude: number;
};

export enum SocialMediaPlatform {
  Facebook = "facebook",
  Instagram = "instagram",
  Youtube = "youtube",
  Twitter = "twitter",
  Weibo = "weibo",
  Vkontakte = "vkontakte",
  Trip_advisor = "trip_advisor",
  TikTok = "tik_tok",
  LinkedIn = "linkedin",
  WeChat = "we_chat",
  Reddit = "reddit",
  Whatsapp = "whatsapp",
}

export type SocialMediaLink = {
  url: string;
  linkType: SocialMediaPlatform;
};

export enum JoinRequestStatus {
  New = "new",
  Approved = "approved",
  Rejected = "rejected",
}

export type CompanyName = {
  businessName: string;
};

export type ContactDetail = {
  phone: string;
  email: string;
};

export type PostalAddressCity = {
  city: string;
};

export type ProductCuration = {
  curationStatus: CurationStatus;
  productCurationStatus: ProductCurationStatus;
};

export enum BulletinTargetGroup {
  DMO = "dmo",
  TC = "tc",
  API = "api",
}

export enum PricingType {
  Free = "free",
  Chargeable = "chargeable",
  Unknown = "unknown",
}

export type ProductFormLink = {
  id?: string;
  url: string;
  userVerifiedAt: string;
  userVerifiedBy: string;
};

export type TimeTuple = [string, string];

export type DropdownOption = {
  value: number;
};

// Type for ApolloClient from useApolloClient hook to bypass ts eslint rules
// (that dont allow the use of object) since the hook doesnt support generic types
export type ApolloClientObject = ApolloClient<object>; //eslint-disable-line

export type CheckTokenResult = {
  tokenOk: boolean;
  error?: unknown;
};

export type AffectedRowsResult = {
  affected_rows: number;
};

export type IdResult = {
  id: string;
};

export enum ImportSteps {
  Connect = "connect",
  Confirm = "confirm",
  Import = "import",
  Upload = "uploaded",
}

export type ExternalVendorId = {
  id: string;
};

export type ExternalAccount = {
  vendorId: string;
  companyName: string;
};

export type ExternalProductPreview = {
  id: string;
  companyId: string;
  imageId: string;
  url: string;
  name: string;
};

export type BokunJohkuInsertResponse = {
  productId: string;
  errors: number;
};

export type ProductsToImportType = {
  productId: string;
  name: string;
};

export type OpeningHours = {
  open: boolean;
  weekday: Weekday;
  opens: string | null;
  closes: string | null;
  date: string | null;
};

export type OpeningHoursException = {
  id: string;
  start: string | null;
  end: string | null;
  openingHours: OpeningHours[];
};

export type BusinessHours = {
  id: string;
  default: OpeningHours[];
  exceptions: OpeningHoursException[];
};

export type OpeningHoursWeekday = Weekday | "multiple";

export type OpeningHoursInputFormValues = {
  closed: boolean;
  opens: string;
  closes: string;
  date?: string;
};

export type OpeningHoursDay = {
  labelKey: string;
  date?: Moment;
  weekday: Weekday;
  defaultValues?: OpeningHoursInputFormValues;
};

export type GroupedProducts = {
  [key: string]: string | string[];
};

export type ProductGroup = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  published: boolean;
  publishedAt: string | null;
  publishingId: string;
  permissionId: string;
  productsAggregate?: AggregateCount;
  permissions: {
    id: string;
    role: ProductGroupRole;
    user: {
      id: string;
      name: string;
    };
    company: {
      id: string;
      businessName: string;
    };
  };
};

export type ProductName = {
  name: string;
  language: Language;
};

export type ProductGroupData = ProductGroup &
  ProductGroupProducts & {
    permissions: ProductGroupPermission[];
    deletedProducts?: {
      product: HasuradbDeletedProduct;
    }[];
  };

export type ProductGroupPermissionData = {
  permissions: { role: string; userId?: string; companyId?: string }[];
};

export type ProductGroupProducts = {
  products: {
    product: HasuradbProduct;
  }[];
};

export type ProductGroupPermission = {
  id: string;
  role: ProductGroupRole;
  company?: {
    id: string;
    businessName: string;
  };
  user?: {
    id: string;
    name: string;
  };
};

export type ProductGroupInput = {
  name: string;
  description: string;
};

export type ProductGroupProductInput = {
  productId: string;
  productGroupdId?: string;
};

export type GroupIdParam = {
  groupId: string;
};

export type ProductIdParam = {
  productId: string;
};

export type ProductGroupStatus = "open" | "edit" | "published";

export type PopoverStateType = { isOpen: boolean; id: string };

export enum ProductGroupRole {
  Admin = "admin",
  Editor = "editor",
  Viewer = "viewer",
  Manager = "manager",
}

export type AlertProps = {
  type: NotificationType;
  text: string;
};
