import {
  DropdownOption,
  FilterMode,
  FilterType,
  OrderBy,
  ProductFilters,
  ProductIdParam,
  UserRole,
} from "../../../types";
import {
  CREATE_PRODUCT_GROUP,
  EDIT_PRODUCT_GROUP,
  PRODUCT_ID_PARAM,
} from "../productGroupRouteConstants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import buildPublishedProductsForProductGroupQuery, {
  GetPublishedProductsForProductGroupResult,
  GetPublishedProductsForProductGroupVariables,
} from "../../../graphqlQueries/getPublishedProductsForProductGroup";

import AddProductsProductListItem from "./AddProductsProductListItem";
import { BasicInfoModal } from "../../DataHubModal";
import { DEFAULT_PAGE_SIZE_OPTIONS } from "../../../utils/pagingUtils";
import { DataHubButton } from "../../DataHubButton";
import { DataHubPopOver } from "../../DataHubPopOver";
import Filters from "../../curatorWorkspace/Filters";
import { HasuradbProduct } from "../../../graphqlQueries/getProduct";
import Icon from "../../Icon";
import LastEdit from "../../LastEdit";
import ModalProductListHeader from "./ModalProductListHeader";
import { Paging } from "../../Paging";
import PublishedProductList from "../../products/PublishedProductList";
import ViewProduct from "../../products/ViewProduct";
import { breakpointPixels } from "../../../css/breakpoints";
import { getEditPath } from "../../../utils/productGroupUtils";
import { getHasuraRoleContext } from "../../../utils/functions";
import { getQueryFilterVariables } from "../../../utils/filterUtils";
import styled from "styled-components";
import { useIsSmallScreen } from "../../MediaQuery";
import useProductFilters from "../../hooks/useProductFilters";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const ProductView = styled.div`
  overflow-y: auto;
`;

const PopOverContainer = styled.div`
  width: 350px;
  max-height: 40em;
  overflow-x: hidden;
`;

const StyledModalContainer = styled(BasicInfoModal)`
  .add-products-to-group-modal {
    .modal-body {
      padding: 0 !important;
    }
  }
  .product-modal-column {
    overflow-y: scroll;
    max-height: 100%;
  }
  .filters-column {
    min-width: 250px;
    max-width: 250px;
  }
  .modal-content-container {
    width: 100%;
    .product-modal-column {
      width: 50%;
    }
    @media (max-width: ${breakpointPixels.sm}) {
      min-width: 100%;
      flex-direction: column-reverse !important;
      .product-modal-column {
        width: 100%;
      }
    }
  }
  .published-products-list-container {
    flex-basis: 100% !important;
    overflow-y: visible !important;
  }
  .product-list-paging-container {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -4px 2px 0px;
    position: sticky;
  }
`;

const initialFilters = {};

type AddProductsToGroupModalProps = {
  onHide: () => void;
  onSave: (selectedProducts: HasuradbProduct[]) => void;
  rootPagePath: string;
  groupId?: string;
  oldProducts: HasuradbProduct[];
  tcCompanyIds?: string[];
};

const AddProductsToGroupModal = ({
  onHide,
  onSave,
  rootPagePath,
  groupId,
  oldProducts,
  tcCompanyIds,
}: AddProductsToGroupModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const listElement = useRef<HTMLDivElement>(null);
  const isSmallScreen = useIsSmallScreen();
  const [selectedProducts, setSelectedProducts] = useState<HasuradbProduct[]>([]);
  const [showNewSelected, setShowNewSelected] = useState(false);

  const [page, setPage] = useState(0);
  const [pageAmount, setPageAmount] = useState(0);
  const [selectedAmountOption, setSelectedAmountOption] = useState<DropdownOption>(
    DEFAULT_PAGE_SIZE_OPTIONS[0]
  );
  const [orderBy] = useState<OrderBy>(OrderBy.Ascending);

  const { filters, setFilters } = useProductFilters(initialFilters);

  const limit = selectedAmountOption.value;

  const [
    getPublishedProductsForProductGroupQuery,
    getPublishedProductsForProductGroupFilters,
  ] = useMemo(() => {
    const queryVariables = getQueryFilterVariables(filters, undefined, tcCompanyIds);
    const query = buildPublishedProductsForProductGroupQuery(queryVariables);
    return [query, queryVariables];
  }, [filters, tcCompanyIds]);

  const { data: getPublishedProductsData, loading: getPublishedProductsLoading } = useQuery<
    GetPublishedProductsForProductGroupResult,
    GetPublishedProductsForProductGroupVariables
  >(getPublishedProductsForProductGroupQuery, {
    context: getHasuraRoleContext(UserRole.ViewOpenProducts),
    variables: {
      ...getPublishedProductsForProductGroupFilters,
      limit,
      offset: page * limit,
      orderBy: orderBy,
    },
    fetchPolicy: "cache-first",
  });

  const selectedProductId = useRouteMatch<ProductIdParam>()?.params.productId;

  const products = useMemo(() => {
    return getPublishedProductsData?.product ?? [];
  }, [getPublishedProductsData]);

  const productsLength = getPublishedProductsData?.productAggregate.aggregate.count ?? 0;

  const productListData = useMemo(() => products, [products]);

  useEffect(() => {
    if (!getPublishedProductsLoading) {
      const totalProductCount: number =
        getPublishedProductsData?.productAggregate.aggregate.count ?? 0;
      const pageAmount = totalProductCount === 0 ? 0 : Math.ceil(totalProductCount / limit);

      setPageAmount(pageAmount);
    }
  }, [getPublishedProductsData, getPublishedProductsLoading, limit, pageAmount]);

  const onAddHandler = useCallback(
    (product: HasuradbProduct) => {
      const shouldRemoveProduct = selectedProducts.map((it) => it.id).includes(product.id);

      if (shouldRemoveProduct) {
        return setSelectedProducts((curr) => curr.filter((it) => it.id !== product.id));
      }

      setSelectedProducts([...selectedProducts, product]);
    },
    [selectedProducts]
  );

  const showProductDetails = useCallback(
    (id: string) =>
      history.push(
        `${groupId ? getEditPath(rootPagePath, groupId) : `${rootPagePath}/create`}/${id}`
      ),
    [history, rootPagePath, groupId]
  );

  const handleFilterChange = useCallback(
    (newFilters: ProductFilters) => {
      setFilters({
        ...newFilters,
      });
      setPage(0);
    },
    [setFilters]
  );

  const selectAllProducts = useCallback(() => setSelectedProducts(productListData), [
    productListData,
  ]);
  const removeSelectedProducts = useCallback(() => setSelectedProducts([]), []);

  const ProductViewRender = () => (
    <ProductView>
      <ViewProduct
        viewMode="publishedProducts"
        renderToolbar={({ product }) => (
          <div className="mt-3 px-3">
            <LastEdit dateStr={product.updatedAt} />
          </div>
        )}
        showCarousel={!isSmallScreen}
        showOverlay={isSmallScreen}
      />
    </ProductView>
  );

  const ModalFooterRender = () => (
    <>
      <DataHubPopOver
        triggerButton={
          <DataHubButton
            className="mr-3"
            variant="outline"
            iconPosition="right"
            iconElement={<Icon name="chevron-down" size="small" className="ml-3" />}
            onClick={() => setShowNewSelected((value) => !value)}
          >
            {t("groupedProducts.modalSelectedProductsButton", {
              amount: selectedProducts.length,
            })}
          </DataHubButton>
        }
        placement="top"
        show={showNewSelected}
        header={t("groupedProducts.modalPopoverHeader")}
      >
        <PopOverContainer>
          <ProductView>
            {selectedProducts.map((it) => (
              <AddProductsProductListItem
                key={it.id}
                item={it}
                selected={false}
                onButtonClick={onAddHandler}
                isPreview={true}
              />
            ))}
          </ProductView>
        </PopOverContainer>
      </DataHubPopOver>

      <DataHubButton className="mr-3" variant="secondary" onClick={() => onHide()}>
        {t("common.cancel")}
      </DataHubButton>
      <DataHubButton
        variant="primary"
        style={{
          minWidth: "150px",
        }}
        onClick={() => {
          onSave([...oldProducts, ...selectedProducts]);
          onHide();
        }}
      >
        {t("productInfo.update")}
      </DataHubButton>
    </>
  );

  return (
    <StyledModalContainer
      dialogClassName="add-products-to-group-modal"
      centered={true}
      header={<h3>{t("groupedProducts.modalHeader")}</h3>}
      size="fullscreen"
      onCancel={onHide}
      footer={<ModalFooterRender />}
      children={
        <div className="border-bottom h-100 d-flex flex-row">
          {!isSmallScreen && (
            <div className="filters-column border bg-gray-100 product-modal-column">
              <Filters
                initialFilters={initialFilters}
                filterType={FilterType.DesktopFilters}
                filters={filters}
                onFilterChange={handleFilterChange}
                filterMode={FilterMode.PublishedProducts}
              />
            </div>
          )}
          <div className="d-flex flex-row modal-content-container">
            <div className="border product-modal-column">
              <ModalProductListHeader
                productsLen={productsLength}
                addAll={selectAllProducts}
                removeAll={removeSelectedProducts}
              />
              <div className="mt-3 border-top position-relative w-100">
                <div className="w-100 d-flex">
                  <PublishedProductList
                    ref={listElement}
                    selectedProductId={selectedProductId}
                    header={<></>}
                    products={products.map((product) => ({
                      id: product.id,
                      productImages: product.productImages,
                      type: product.type,
                      company: product.company,
                      companyId: product.company.id,
                      postalAddresses: product.postalAddresses,
                      productInformations: product.productInformations,
                      updatedAt: product.updatedAt,
                    }))}
                    loading={getPublishedProductsLoading}
                    rootPagePath={rootPagePath}
                    productListItem={
                      <>
                        {productListData.map((item) => (
                          <AddProductsProductListItem
                            key={item.id}
                            item={item}
                            onItemClick={showProductDetails}
                            selected={item.id === selectedProductId}
                            addedNew={selectedProducts.some((p) => p.id === item.id)}
                            addedOld={oldProducts.some((p) => p.id === item.id)}
                            onButtonClick={onAddHandler}
                          />
                        ))}
                      </>
                    }
                    paging={
                      <Paging
                        productsLength={productsLength}
                        totalProductCount={productsLength}
                        productsLoading={getPublishedProductsLoading}
                        page={page}
                        setPage={setPage}
                        selectedAmountOption={selectedAmountOption}
                        setSelectedAmountOption={setSelectedAmountOption}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="justify-content-start border product-modal-column">
              <Route path={`${rootPagePath}${EDIT_PRODUCT_GROUP}/${PRODUCT_ID_PARAM}`}>
                <ProductViewRender />
              </Route>
              <Route path={`${rootPagePath}${CREATE_PRODUCT_GROUP}/${PRODUCT_ID_PARAM}`}>
                <ProductViewRender />
              </Route>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AddProductsToGroupModal;
