import { gql } from "@apollo/client";
import { Company } from "./getCompanyNames";

export const fetchOrganisationQuery = gql`
  query FetchOrganisation($businessId: String!) {
    FetchOrganisation(businessId: $businessId) {
      name
      businessid
      marketing_name
      status
    }
  }
`;

export type fetchOrganisationVariables = {
  businessId: string;
};

export type fetchOrganisationQueryResult = {
  FetchOrganisation: crmOrganisation;
};

export type crmOrganisation = {
  name: string;
  businessid: string;
  marketing_name?: string;
  accountsite_id?: string;
  companyIds?: boolean;
  companyNames?: Company[];
  status?: "not_found" | "ok";
};

export default fetchOrganisationQuery;
