import { ProductGroupRole, SelectOption, UserRole } from "../../types";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";
import React from "react";
import getCompaniesForProductGroupQuery, {
  getCompaniesForProductGroupQueryResult,
} from "../../graphqlQueries/getCompaniesForProductGroup";
import { useQuery } from "@apollo/client";

import { DataHubButton } from "../DataHubButton";
import { basicSelectStyles, DatahubSelect } from "../DatahubSelect";
import { Form } from "react-bootstrap";
import Icon from "../Icon";
import { ProductGroupParticipantFormValue } from "./GroupForm";
import { getHasuraRoleContext } from "../../utils/functions";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { TDH_COMPANY_ID } from "../../utils/constants";

const ADDED_ORGANIZATIONS_LABEL = "organizationsToInvite";
const USER_ROLE = "rolesToChange";

type AddParticipantsToGroupSectionProps = {
  isLocked?: boolean;
  currentOrganizations: ProductGroupParticipantFormValue[];
  currentUsers: ProductGroupParticipantFormValue[];
  setCurrentOrganizations: (users: ProductGroupParticipantFormValue[]) => void;
  setCurrentUsers: (organizations: ProductGroupParticipantFormValue[]) => void;
  currentRole: ProductGroupRole | null;
};

const AddParticipantsToGroupSection: React.FunctionComponent<AddParticipantsToGroupSectionProps> = ({
  isLocked,
  currentOrganizations,
  currentUsers,
  setCurrentOrganizations,
  setCurrentUsers,
  currentRole,
}) => {
  const { t } = useTranslation();
  const storedCompanyId = localStorage.getItem(TDH_COMPANY_ID);
  const canManage =
    currentRole === ProductGroupRole.Admin || currentRole === ProductGroupRole.Manager;

  const { setValue } = useFormContext();
  const upperFirst = (text?: string) => text?.replace(/^./, text[0].toUpperCase());

  const onParticipantSelect = (selectedOrganizations: SelectOption[]) => {
    setValue(ADDED_ORGANIZATIONS_LABEL, selectedOrganizations, { shouldDirty: true });
  };

  const { data: getCompaniesData } = useQuery<getCompaniesForProductGroupQueryResult>(
    getCompaniesForProductGroupQuery,
    {
      context: getHasuraRoleContext(UserRole.ManageProductGroup),
      fetchPolicy: "cache-first",
    }
  );

  let companyOptions =
    getCompaniesData?.company
      .map((company) => ({
        label: company.businessName,
        value: company.id,
      }))
      .filter((company) => company.value !== storedCompanyId) || [];

  companyOptions = companyOptions.filter((company) => company.value !== storedCompanyId);

  const addedParticipants = [
    ...currentUsers.map((u) => ({ ...u, type: "user" })),
    ...currentOrganizations.map((u) => ({ ...u, type: "company" })),
  ].sort((a) => (a.role === ProductGroupRole.Admin ? -1 : 1));

  const roles = [ProductGroupRole.Manager, ProductGroupRole.Editor].map((role) => ({
    label: upperFirst(role),
    value: role,
  }));

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>
          {t("groupedProducts.addParticipantsInstructionsHeader")}
        </FormSectionHeader>
        {t("groupedProducts.addParticipantsSectionDescription")}
      </FormDescription>
      <FormSectionContent>
        <div>
          {addedParticipants && (
            <div className="mb-5">
              <div className="font-weight-bold mb-3">
                {t("groupedProducts.addedParticipantsHeader")}
              </div>
              {addedParticipants.map((participant: ProductGroupParticipantFormValue) => (
                <div
                  key={participant.value}
                  className="bg-gray-100 border-gray-400 px-4 py-2 mb-2 w-100 d-flex flex-row justify-content-between align-items-center"
                >
                  <div>{participant.label}</div>
                  {/*
                   * Only show removal buttons if the current user is admin and hide them
                   * in case the current listed user is admin
                   */}
                  {canManage && participant.role !== ProductGroupRole.Admin && (
                    <div className={"ml-auto d-flex"}>
                      <DataHubButton
                        variant="ghost-pink"
                        className="mr-2"
                        disabled={isLocked}
                        iconElement={<Icon name="trash" size="small" className="mr-1" />}
                        onClick={() => {
                          const newUsers = currentUsers.filter(
                            (user) => user.value !== participant.value
                          );
                          const newOrganizations = currentOrganizations.filter(
                            (organization) => organization.value !== participant.value
                          );
                          setCurrentUsers(newUsers);
                          setCurrentOrganizations(newOrganizations);
                        }}
                      >
                        {t("common.delete")}
                      </DataHubButton>
                      <Controller
                        defaultValue={{
                          label: upperFirst(participant.role),
                          value: participant.role,
                        }}
                        name={`${USER_ROLE}.${participant.type}.${participant.value}`}
                        render={({ ref, ...props }) => (
                          <Select
                            {...props}
                            options={roles}
                            styles={basicSelectStyles}
                            className="font-weight-bold text-small"
                          />
                        )}
                      />
                    </div>
                  )}
                  {participant.role === ProductGroupRole.Admin && (
                    <div className="font-weight-bold bg-gray-300 p-1 text-small">
                      {upperFirst(participant.role)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {!isLocked && canManage && (
            <>
              <div className="pb-4">
                <div className="font-weight-bold mb-3">
                  {t("groupedProducts.AddMoreParticipantsHeader")}
                </div>
                <Form.Label>{t("groupedProducts.organizationNameLabel")}</Form.Label>
                <Controller
                  name={ADDED_ORGANIZATIONS_LABEL}
                  defaultValue={[]}
                  render={({ value }) => (
                    <DatahubSelect
                      onChange={(values) => onParticipantSelect(values as SelectOption[])}
                      isMulti
                      value={value}
                      isRelative
                      options={companyOptions.filter((company) => {
                        return !currentOrganizations?.find(
                          (added) => added.value === company.value
                        );
                      })}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
      </FormSectionContent>
    </FormSection>
  );
};

export default AddParticipantsToGroupSection;
