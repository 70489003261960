import { TableEllipsis } from "../../types/companyTypes";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  getCuratorCompaniesInAreas,
  GetCuratorCompaniesInAreasResult,
  GetCuratorCompaniesInAreasVariables,
} from "../../graphqlQueries/getCuratorCompaniesInAreas";
import { getHasuraRoleContext } from "../../utils/functions";
import { UserRole } from "../../types";
import { NotAvailable } from "../products/NotAvailable";
import moment from "moment/moment";
import { Card, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Icon from "../Icon";

type AdminDashboardEllipsisBodyProps = {
  ellipsis: TableEllipsis;
};

export const AdminDashboardEllipsisBody = ({ ellipsis }: AdminDashboardEllipsisBodyProps) => {
  const { t } = useTranslation();

  const getUniquePostalCodes = (postalCodes: string[]) => {
    const uniquePostalCodes = new Set(postalCodes);
    return Array.from(uniquePostalCodes);
  };

  const postalCodes = getUniquePostalCodes(ellipsis.productPostalCodes);

  const { data, loading } = useQuery<
    GetCuratorCompaniesInAreasResult,
    GetCuratorCompaniesInAreasVariables
  >(getCuratorCompaniesInAreas, {
    skip: postalCodes.length === 0,
    variables: {
      postalCodes,
    },
    context: getHasuraRoleContext(UserRole.ManageCuration),
    fetchPolicy: "cache-and-network",
  });

  const CompanyCurators = () => {
    const [open, setOpen] = useState<string>("");
    const companyNames = data?.company.map(({ officialName }) => officialName);

    if (!companyNames || companyNames.length < 1) {
      return <NotAvailable />;
    }

    return (
      <>
        {data?.company.map((c, i) => (
          <div key={i}>
            <strong>{t("companyTable.officialName")}</strong>
            <p className="mb-2">{c.officialName}</p>
            <Accordion activeKey={open}>
              <Card className="bg-transparent border-0">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  style={{ zIndex: 2 }}
                  onClick={() => setOpen(open ? "" : "0")}
                  className="bg-transparent border-0 p-0 mb-2 cursor-pointer"
                >
                  <p className={"strong d-inline font-heavy color-primary"}>
                    {t("common.showMore")}
                  </p>
                  <Icon
                    name="chevron-right"
                    size="medium"
                    color="primary"
                    style={{ transform: `rotate(${open ? "90" : "0"}deg)` }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p-0 pb-4">
                    {c.businessName && (
                      <>
                        <strong>{t("companyTable.marketingNameHeader")}</strong>
                        <p className="mb-2">{c.businessName}</p>
                      </>
                    )}
                    {c.postalAddresses && (
                      <>
                        <strong>{t("companyTable.cityHeader")}</strong>
                        <p className="mb-2">
                          {c.postalAddresses ? c.postalAddresses[0]?.city : "-"}
                        </p>
                      </>
                    )}
                    {c.contactDetails &&
                      c.contactDetails.map((cd, i) => (
                        <div className="pb-2" key={i}>
                          <strong>{t("companyInfo.contactDetailsHeader")}</strong>
                          <p className="m-0">{cd.email}</p>
                          <p className="m-0">{cd.phone}</p>
                        </div>
                      ))}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="bg-gray-100 py-2 px-3">
      <div>
        <h4>{t("companyTable.officialName")}</h4>
        <p>{ellipsis.officialName}</p>
      </div>
      <div>
        <h4>{t("companyTable.joinedHeader")}</h4>
        <p>{moment(ellipsis.createdAt).format("D.M.YYYY")}</p>
      </div>
      <div>
        <h4>{t("companyTable.companyCurator")}</h4>
        {loading ? <Spinner animation="border" /> : <CompanyCurators />}
      </div>
    </div>
  );
};
